<template>
	<div class="animated fadeIn p-3">
		<template v-if="isAdminOrCorporate">
			<b-alert
				show
				variant="secondary">
				<div class="row">
					<div class="col">
						<search
							ref="searchComp"
							v-model="distributor"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor', tree:'unilevel'}"
							:init-value="String(distributor)"
							return-key="id"
							class="mb-3"
							q="label" />
						<div class="row">
							<div class="col">
								<b-button
									:disabled="loading"
									variant="primary"
									class="btn mx-1"
									@click="changeDistributor()">
									{{ translate('search') }}
								</b-button>
								<b-button
									:disabled="loading"
									variant="secondary"
									class="btn mx-1"
									@click="clearDistributor()">
									{{ translate('clear') }}
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</b-alert>
			<hr>
		</template>
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<search
							v-model="filters.user_id"
							:init-value="filters.user_id"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor', tree:'unilevel'}"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th>
							#
						</th>
						<th>
							{{ translate('user_id') }}
						</th>
						<th>
							{{ translate('username') }}
						</th>
						<th>
							{{ translate('country') }}
						</th>
						<th
							class="text-center">
							{{ translate('trip_points') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index"
						class="text-nowrap">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							<span
								v-if="!isAdminOrCorporate"
								class="badge badge-primary">
								{{ item.id }}
							</span>
							<span
								v-else
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.id.toString() })">{{ item.id }}
							</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${item.attributes.country.toUpperCase()}.png`)"
								:alt="translate(item.attributes.country.toLowerCase())">
						</td>
						<td class="align-middle text-center">
							<template v-if="item.attributes.qualified">
								<span class="badge badge-success">
									{{ translate('qualified') }}
								</span>
							</template>
							<template v-else>
								{{ item.attributes.points }}
							</template>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import {
	PersonalBusinessVolume as PBV, Grids, Countries,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import Sponsored from '@/views/Network/mixins/Sponsored';
import {
	PERIOD_FILTERS as dateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
	MDY_FORMAT as mdyFormat,
	custom,
	allTime, YMDHMS_FORMAT,
} from '@/settings/Dates';
import DataFilter from '@/components/DataFilter';
import Reports from '@/util/Report';
import { SEARCH_USERS } from '@/config/endpoint';
import WindowSizes from '@/mixins/WindowSizes';
import { PAGINATION } from '@/settings/RequestReply';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'UnilevelTripPoints',
	messages: [PBV, Grids, Countries],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect, WindowSizes, Sponsored],
	data() {
		return {
			alerts: new this.$Alert(),
			dateFormat: YMDHMS_FORMAT,
			dateRanges,
			calendarConfig,
			mdyFormat,
			custom,
			allTime,
			endpoint,
			rangeStart: '',
			rangeEnd: '',
			sortData: {},
			tripPointsReport: new Reports(),
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (!vm.isAdminOrCorporate && !Number(vm.$cookie.get('is_jns'))) {
				vm.$router.replace({ name: 'Home' });
			}
		});
	},
	computed: {
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
		loading() {
			return !!this.tripPointsReport.data.loading;
		},
		distributorId() {
			let { distributorId } = this.$route.params;
			if (typeof distributorId === 'undefined') {
				distributorId = this.$user.details().id;
			}
			return distributorId;
		},
		data() {
			try {
				const { data } = this.tripPointsReport.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		pagination() {
			try {
				return this.tripPointsReport.data.response.data.meta.pagination;
			} catch (e) {
				return {};
			}
		},
	},
	mounted() {
		if (this.distributorId) {
			this.tripPointsReport.getUnilevelTripPoints(this.distributorId);
		}
	},
	methods: {
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.tripPointsReport.getTripPointsReport(options);
		},
	},
};
</script>
